angular.module('app', ['ui.router'])

angular
  .module('app')
  .config(function (
    $stateProvider,
    $urlRouterProvider,
    $sceProvider,
    $locationProvider
  ) {
    $sceProvider.enabled(false)
    $locationProvider.html5Mode(true)
    $stateProvider
      .state('app', {
        url: '/',
        templateUrl: 'app/home/home.html'
      })
      .state('home', {
        url: '/home',
        templateUrl: 'app/home/home.html',
        controller: 'HomeCtrl'
      })
      .state('movies', {
        url: '/movies',
        templateUrl: 'app/movies/movies.html',
        controller: 'MoviesCtrl'
      })
      .state('movie-details', {
        url: '/movies/:mid',
        templateUrl: 'app/movie-details/movie.html',
        controller: 'MovieDetailsCtrl'
      })
      .state('upcoming', {
        url: '/upcoming',
        templateUrl: 'app/upcoming/upcoming.html',
        controller: 'UpcomingCtrl'
      })
      .state('about', {
        url: '/about',
        templateUrl: 'app/about/about.html',
        controller: 'AboutCtrl'
      })
      .state('team', {
        url: '/team',
        templateUrl: 'app/team/team.html',
        controller: 'TeamCtrl'
      })

    $urlRouterProvider.otherwise('/')
  })
  .constant('movies', [
    {
      "_id": "somewhat-true-tales-of-sir-william",
      "name": "The Somewhat True Tales of Sir William",
      "description": "The pompous Sir William and his faithful servant Lyle stumble across a fair maiden in the forest. His long career is full of glorious deeds worth retelling! Maybe…\n\nCreated for the 2022 CWVFF 48hr film race.",
      "youtube": "yPCSnIPhBFQ"
    },
    {
      "_id": "hero",
      "name": "HERO",
      "description": "Hero. What does that mean? What do you think of when you hear it? Do you imagine a warrior saving a maiden from an evil dragon? Or do you see a valiant man saving a city from utter destruction? The word hero takes on a whole different meaning when looked at in a different light. Heroes are the ones that sacrifice for our good without us sometimes even knowing it. Who expect nothing in return even though they give you everything.",
      "youtubeTrailer": "ZHUPKI6pt88",
      "youtube": "PhG0THwy2Qg"
    },
    {
      "_id": "fugitives",
      "name": "Fugitives",
      "description": "A family flees across a kingdom attempting to escape from a terror that is chasing them.",
      "youtube": "li03aYNzWpw"
    },
    {
      "_id": "sir-kendrick-of-penwell",
      "name": "Sir Kendrick of Penwell",
      "description": "Sir Kendrick's story is one of tragedy, but it is also a story of great victory. The film is based on the book \"Sir Kendrick and the Castle of Bel Lione\" written by Chuck Black.",
      "basedBookUrl": "http://www.perfect-praise.com/page29.html",
      "basedBook": "Sir Kendrick and the Castle Bel Lione",
      "basedAuthorUrl": "https://www.facebook.com/ChuckBlack.Author",
      "basedAuthor": "Chuck Black",
      "youtubeTrailer": "GFux4fwwpvc",
      "youtube": "I9NBIitvd9Y"
    },
    {
      "_id": "paladins-conquest",
      "name": "Paladin's Conquest",
      "description": "King Emory has disappeared leaving the kingdom into the hands of Talvace, a corrupted Lord, who craves power and blood. As he expands his reign, four brave warriors arise to defend the land, and wait for the return of King Emory. The four warriors stall the lord’s expansion, and disrupt many of his plots. Furious with the delay, Talvace sends out his commander to destroy the Paladins, and thus end the threat to his dominion.",
      "youtube": "IoE01crpYak",
      "youtubeTrailer": "HI0hYFn5JJo"
    },
    {
      "_id": "the-right-thing",
      "name": "The Right Thing",
      "description": "This is a scene from Kingdom's Hope from The Kingdom Series written by Chuck Black. This film was created in 8 hours.",
      "youtube": "w0YcRF0iLgU"
    },
    {
      "_id": "kingdoms-hope-camelot-years",
      "name": "Kingdoms Hope, Camelot years",
      "description": "This is a film for Chuck Black's video contest. It is set in-between the time that Quinn becomes King, and Leinad trains Knights to defend Chessington. Although not entirely accurate, it is logical that these events could've taken place.",
      "basedBook": "Kingdoms Hope",
      "basedBookUrl": "http://www.perfect-praise.com/page29.html",
      "basedAuthor": "Chuck Black",
      "basedAuthorUrl": "https://www.facebook.com/ChuckBlack.Author",
      "youtube": "McvqJluy9j0"
    },
    {
      "_id": "rise-of-darkness",
      "name": "Rise Of Darkness",
      "description": "A man seeks revenge when his friend is assassinated by a reckless Warlord. His life changes when he bumps into a strange man.",
      "youtube": "UyUO60bA03c"
    },
    {
      "_id": "defenders-of-theldis",
      "name": "The Defenders of Theldis (Official Trailer)",
      "description": "Theldis used to be prosperous, but the Master of Darkness has come to the land and has destroyed it. Its people, and all the dreams of a peaceful land. Just when The Master seems about to take completely over, they come. They call them the Defenders. They have come to stop the Master. But many trials are before them. They have only one chance, and they will take it!",
      "youtube": "BrwILucSde8"
    },
    {
      "_id": "northbound",
      "name": "Northbound",
      "description": "The Northbound Quinlan is headed towards Chesney Isle, an island just west of the Wastelands. Along the way he runs into an ambitious young woman in the midst of a north-country squabble.",
      "basedBook": "Sir Quinlan and the Swords of Valore",
      "basedBookUrl": "http://www.perfect-praise.com/page29.html",
      "basedAuthor": "Chuck Black",
      "basedAuthorUrl": "https://www.facebook.com/ChuckBlack.Author",
      "youtube": "MBrajKqZVng"
    },
    {
      "_id": "beyond-reality",
      "name": "Beyond Reality",
      "description": "Everly has an imagination that can take her anywhere. In a coffee shop one day, she begins reading a book and finds herself as the main character. (Adam Young Scores Short Film Contest)",
      "youtube": "HJkSCfr8JQY"
    },
    {
      "_id": "inspiring-scriptural-skit",
      "description": "Directed by: Elijah Perry\nCreated & Choreographed by: Grace Garnaat\nCamera Operators: Jacob Perry & Quinten Brendemuhl\nUAV Operator: Quinten Brendemuhl\nMusic composed by: Mark Petrie",
      "name": "Inspiring Scriptural Skit",
      "youtube": "LPTYNuLjHlM"
    }
  ])
  .constant('projects', [
    {
      "_id": "5a94993c9823d6003e7bc2fa",
      "title": "Paladin's Revenge",
      "description": "Sequal to Paladin's Conquest",
      "date": "TBD"
    }
  ])
  .constant('team', [
    {
      "_id": "5a9490b1737ab500223c2fc4",
      "name": "Elijah Perry",
      "role": "Director / Producer / Writer",
      "bio": "Elijah is the Director, Producer, and Writer of the group. He leads the production, and makes a lot of the decisions.",
      "image": "https://s3.amazonaws.com/citc-images/team/18596486_220256928475696_879251887_o.jpg"
    },
    {
      "_id": "5a949303737ab500223c2fc7",
      "name": "Havah Perry",
      "role": "Costume Designer",
      "bio": "Havah has a creative mind, and is the costume designer of the group. She also helps with editing the script.",
      "image": "https://s3.amazonaws.com/citc-images/team/18552986_220259455142110_294707848_o.jpg"
    },
    {
      "_id": "5a9492ca737ab500223c2fc5",
      "name": "Joshua Perry",
      "role": "Set Designer / VFX Coordinator",
      "bio": "Joshua is usually the set designer, and Visual Effects coordinator. He also helps the group in other areas as well.",
      "image": "https://s3.amazonaws.com/citc-images/team/18553127_220257325142323_816889560_o.jpg"
    },
    {
      "_id": "5a9492e8737ab500223c2fc6",
      "name": "Jacob Perry",
      "role": "Director of Photography",
      "bio": "Jacob is the DP, and heads up most of the cinematography. He also helps with the location scouting when it's needed.",
      "image": "https://s3.amazonaws.com/citc-images/team/18552857_220259155142140_683651576_o.jpg"
    }
  ])
angular.module('app').controller('AboutCtrl', function ($scope) {})

angular.module('app').directive('youtube', function ($sce) {
  return {
    restrict: 'EA',
    scope: { code: '=' },
    replace: true,
    template:
      '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" ng-src="{{url}}" frameborder="0" allowfullscreen></iframe></iframe></div>',
    link: function (scope) {
      scope.$watch('code', function (newVal) {
        if (newVal) {
          scope.url = $sce.trustAsResourceUrl(
            'https://www.youtube.com/embed/' + newVal
          )
        }
      })
    }
  }
})

angular.module('app').controller('HomeCtrl', function ($scope) {})

angular
  .module('app')
  .controller('MovieDetailsCtrl', function (
    $scope,
    $stateParams,
    $timeout,
    movies
  ) {
    $scope.loaded = false

    for (var index = 0; index < movies.length; index++) {
      var element = movies[index]

      if (element._id === $stateParams.mid) {
        $scope.movie = element
      }
    }

    $scope.loaded = true
  })

angular.module('app').controller('MoviesCtrl', function ($scope, movies) {
  $scope.loaded = false

  $scope.movies = movies
  $scope.loaded = true
})

angular.module('app').controller('TeamCtrl', function ($scope, team) {
  $scope.team = team
  $scope.loaded = true
})

angular
  .module('app')
  .controller('UpcomingCtrl', function ($scope, projects) {
    $scope.upcoming = projects
    $scope.loaded = true
  })


